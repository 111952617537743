import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _7f4d9b74 = () => interopDefault(import('../pages/aboutUs.vue' /* webpackChunkName: "pages/aboutUs" */))
const _024a7cf3 = () => interopDefault(import('../pages/brands.vue' /* webpackChunkName: "pages/brands" */))
const _c13e036c = () => interopDefault(import('../pages/cc/index.vue' /* webpackChunkName: "pages/cc/index" */))
const _3f87ae09 = () => interopDefault(import('../pages/contact.vue' /* webpackChunkName: "pages/contact" */))
const _d4942c94 = () => interopDefault(import('../pages/ds.vue' /* webpackChunkName: "pages/ds" */))
const _633f7080 = () => interopDefault(import('../pages/favorites.vue' /* webpackChunkName: "pages/favorites" */))
const _3167ce66 = () => interopDefault(import('../pages/hermes.vue' /* webpackChunkName: "pages/hermes" */))
const _4b4f6614 = () => interopDefault(import('../pages/payments.vue' /* webpackChunkName: "pages/payments" */))
const _dc6a1126 = () => interopDefault(import('../pages/random/index.vue' /* webpackChunkName: "pages/random/index" */))
const _3f793fe0 = () => interopDefault(import('../pages/shield.vue' /* webpackChunkName: "pages/shield" */))
const _902bd0a4 = () => interopDefault(import('../pages/shippings.vue' /* webpackChunkName: "pages/shippings" */))
const _1d01bf28 = () => interopDefault(import('../pages/success.vue' /* webpackChunkName: "pages/success" */))
const _e0112fa0 = () => interopDefault(import('../pages/terms.vue' /* webpackChunkName: "pages/terms" */))
const _419b8df4 = () => interopDefault(import('../pages/track.vue' /* webpackChunkName: "pages/track" */))
const _6e66a615 = () => interopDefault(import('../pages/wizard/index.vue' /* webpackChunkName: "pages/wizard/index" */))
const _39321618 = () => interopDefault(import('../pages/hermes-service/order.vue' /* webpackChunkName: "pages/hermes-service/order" */))
const _340cac0a = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _cb20b8bc = () => interopDefault(import('../pages/blog/article/_blog.vue' /* webpackChunkName: "pages/blog/article/_blog" */))
const _86ec0cfc = () => interopDefault(import('../pages/blog/category/_category.vue' /* webpackChunkName: "pages/blog/category/_category" */))
const _7fe4aaa6 = () => interopDefault(import('../pages/blog/tag/_tag.vue' /* webpackChunkName: "pages/blog/tag/_tag" */))
const _096d435f = () => interopDefault(import('../pages/account/_tab.vue' /* webpackChunkName: "pages/account/_tab" */))
const _80b2d830 = () => interopDefault(import('../pages/blog/_q.vue' /* webpackChunkName: "pages/blog/_q" */))
const _0c1b07a3 = () => interopDefault(import('../pages/cart/_step.vue' /* webpackChunkName: "pages/cart/_step" */))
const _30944fde = () => interopDefault(import('../pages/categories/_category.vue' /* webpackChunkName: "pages/categories/_category" */))
const _2a316333 = () => interopDefault(import('../pages/category/_category.vue' /* webpackChunkName: "pages/category/_category" */))
const _71144659 = () => interopDefault(import('../pages/cc/_ba/index.vue' /* webpackChunkName: "pages/cc/_ba/index" */))
const _64eac26e = () => interopDefault(import('../pages/f/_projection.vue' /* webpackChunkName: "pages/f/_projection" */))
const _3ebc1818 = () => interopDefault(import('../pages/featured/_projection.vue' /* webpackChunkName: "pages/featured/_projection" */))
const _3431e0eb = () => interopDefault(import('../pages/forget/_key.vue' /* webpackChunkName: "pages/forget/_key" */))
const _13caa1b6 = () => interopDefault(import('../pages/item/_id.vue' /* webpackChunkName: "pages/item/_id" */))
const _c9676c16 = () => interopDefault(import('../pages/list/_field.vue' /* webpackChunkName: "pages/list/_field" */))
const _7a4b66dc = () => interopDefault(import('../pages/preview/_id.vue' /* webpackChunkName: "pages/preview/_id" */))
const _2a17b947 = () => interopDefault(import('../pages/random/_random1/index.vue' /* webpackChunkName: "pages/random/_random1/index" */))
const _3f07ca3c = () => interopDefault(import('../pages/search/_q.vue' /* webpackChunkName: "pages/search/_q" */))
const _5632cc1b = () => interopDefault(import('../pages/status/_status.vue' /* webpackChunkName: "pages/status/_status" */))
const _54227ccd = () => interopDefault(import('../pages/cc/_ba/_family/index.vue' /* webpackChunkName: "pages/cc/_ba/_family/index" */))
const _21031f6e = () => interopDefault(import('../pages/random/_random1/_random2/index.vue' /* webpackChunkName: "pages/random/_random1/_random2/index" */))
const _5cc13030 = () => interopDefault(import('../pages/random/_random1/_random2/_random3/index.vue' /* webpackChunkName: "pages/random/_random1/_random2/_random3/index" */))
const _6c52b014 = () => interopDefault(import('../components/core/catalogMap.vue' /* webpackChunkName: "components/core/catalogMap.vue" */))
const _96055de0 = () => interopDefault(import('../components/core/dynamicMap.vue' /* webpackChunkName: "components/core/dynamicMap.vue" */))
const _8f6f5036 = () => interopDefault(import('../components/core/catalogFull.vue' /* webpackChunkName: "components/core/catalogFull.vue" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/aboutUs",
    component: _7f4d9b74,
    name: "aboutUs"
  }, {
    path: "/brands",
    component: _024a7cf3,
    name: "brands"
  }, {
    path: "/cc",
    component: _c13e036c,
    name: "cc"
  }, {
    path: "/contact",
    component: _3f87ae09,
    name: "contact"
  }, {
    path: "/ds",
    component: _d4942c94,
    name: "ds"
  }, {
    path: "/favorites",
    component: _633f7080,
    name: "favorites"
  }, {
    path: "/hermes",
    component: _3167ce66,
    name: "hermes"
  }, {
    path: "/payments",
    component: _4b4f6614,
    name: "payments"
  }, {
    path: "/random",
    component: _dc6a1126,
    name: "random"
  }, {
    path: "/shield",
    component: _3f793fe0,
    name: "shield"
  }, {
    path: "/shippings",
    component: _902bd0a4,
    name: "shippings"
  }, {
    path: "/success",
    component: _1d01bf28,
    name: "success"
  }, {
    path: "/terms",
    component: _e0112fa0,
    name: "terms"
  }, {
    path: "/track",
    component: _419b8df4,
    name: "track"
  }, {
    path: "/wizard",
    component: _6e66a615,
    name: "wizard"
  }, {
    path: "/hermes-service/order",
    component: _39321618,
    name: "hermes-service-order"
  }, {
    path: "/",
    component: _340cac0a,
    name: "index"
  }, {
    path: "/blog/article/:blog?",
    component: _cb20b8bc,
    name: "blog-article-blog"
  }, {
    path: "/blog/category/:category?",
    component: _86ec0cfc,
    name: "blog-category-category"
  }, {
    path: "/blog/tag/:tag?",
    component: _7fe4aaa6,
    name: "blog-tag-tag"
  }, {
    path: "/account/:tab?",
    component: _096d435f,
    name: "account-tab"
  }, {
    path: "/blog/:q?",
    component: _80b2d830,
    name: "blog-q"
  }, {
    path: "/cart/:step?",
    component: _0c1b07a3,
    name: "cart-step"
  }, {
    path: "/categories/:category?",
    component: _30944fde,
    name: "categories-category"
  }, {
    path: "/category/:category?",
    component: _2a316333,
    name: "category-category"
  }, {
    path: "/cc/:ba",
    component: _71144659,
    name: "cc-ba"
  }, {
    path: "/f/:projection?",
    component: _64eac26e,
    name: "f-projection"
  }, {
    path: "/featured/:projection?",
    component: _3ebc1818,
    name: "featured-projection"
  }, {
    path: "/forget/:key?",
    component: _3431e0eb,
    name: "forget-key"
  }, {
    path: "/item/:id?",
    component: _13caa1b6,
    name: "item-id"
  }, {
    path: "/list/:field?",
    component: _c9676c16,
    name: "list-field"
  }, {
    path: "/preview/:id?",
    component: _7a4b66dc,
    name: "preview-id"
  }, {
    path: "/random/:random1",
    component: _2a17b947,
    name: "random-random1"
  }, {
    path: "/search/:q?",
    component: _3f07ca3c,
    name: "search-q"
  }, {
    path: "/status/:status?",
    component: _5632cc1b,
    name: "status-status"
  }, {
    path: "/cc/:ba/:family",
    component: _54227ccd,
    name: "cc-ba-family"
  }, {
    path: "/random/:random1/:random2",
    component: _21031f6e,
    name: "random-random1-random2"
  }, {
    path: "/random/:random1/:random2/:random3",
    component: _5cc13030,
    name: "random-random1-random2-random3"
  }, {
    path: "/c/:path*",
    component: _6c52b014,
    name: "catalog-map"
  }, {
    path: "/d/:path*",
    component: _96055de0,
    name: "dynamic-map"
  }, {
    path: "/catalog/:path*",
    component: _8f6f5036,
    name: "catalog-full"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
